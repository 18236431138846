const uids = [
    'eteria','natede-smart','natede-basic','natede','shelfy',
    't-shirt-reconnect-with-your-nature',
    'natede-smart-black-friday','natede-basic-black-friday','natede-smart-christmas-sales','natede-basic-christmas-sales',
    'earth-day-shelfy-offer-amazon-google','earth-day-shelfy-offer-amazon', 'earth-day-shelfy-offer-ecommerce',
    'shelfy-adv', 'shelfy-adv-amazon',
    'secret-shelfy-offer', 'secret-shelfy-offer-amazon',
    'secret-shelfy-offer-price', 'secret-shelfy-offer-amazon-price',
    'secret-shelfy-offer-amazon-meta',
    'secret-shelfy-offer-amazon-google',
    'secret-shelfy-offer-amazon-price-meta',
    'secret-shelfy-offer-amazon-price-google',
    'shelfy-offer-amazon-priceless-meta-uk',
    'shelfy-offer-amazon-price-meta-uk',
    'shelfy-offer-amazon-priceless-google-uk',
    'shelfy-offer-amazon-price-google-uk',
    'shelfy-offer-ecommerce-tiktok-price',
    'shelfy-offer-amazon-price-meta-ita',
    'shelfy-offer-amazon-price-google-ita',
    'prime-days-shelfy-ic-ita', 'prime-days-shelfy-ic-uk', 'prime-days-shelfy-lg-ita', 'prime-days-shelfy-lg-uk','prime-days-shelfy-ic-us'
]

module.exports = uids