
export default {
  name: 'KlarnaWidget',
  props: ['typology', 'amount', 'theme', 'sku'],
  computed:{
    country(){
      const country = this.$store.getters.country
      return country.code
    },
    lang() {
      return this.$i18n.locale
    },
    locale() {
      return `${this.lang}-${this.country}`
    },
    isHidden() {
      return this.country === 'US' || this.country === 'CA' || this.sku && this.sku.includes('MK-FBRAA')
    },
    
  },
  watch: {
    locale(){
      this.refresh()
    },
    amount(){
      this.refresh()
    },
  },
  mounted(){
    setTimeout(()=>{
      this.refresh()
    }, 1000)
  },
  methods: {
    refresh(){
      window?.Klarna?.OnsiteMessaging?.refresh();
      // window?.KlarnaOnsiteService?.push({ eventName: 'refresh-placements' });
      // setTimeout(()=> {
      //   window?.KlarnaOnsiteService?.push({ eventName: 'refresh-placements' });
      // }, 400)
    }
  }
}
