
export default {
  name: 'Tab',
  props: ['index'],
  data() {
    return {
      isActive: this.index === 0 ? true : false
    }
  }
}
