import { render, staticRenderFns } from "./_uid.vue?vue&type=template&id=f22ac4f2&scoped=true&lang=pug"
import script from "./_uid.vue?vue&type=script&lang=js"
export * from "./_uid.vue?vue&type=script&lang=js"
import style0 from "./_uid.vue?vue&type=style&index=0&id=f22ac4f2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f22ac4f2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Hero: require('/opt/build/repo/components/Hero.vue').default,ProductBuy: require('/opt/build/repo/components/product/ProductBuy.vue').default,PatternCircle: require('/opt/build/repo/components/PatternCircle.vue').default,SlicesBlock: require('/opt/build/repo/components/SlicesBlock.vue').default})
