
export default {
  name: 'Tabs',
  data() {
    return {
      selectedIndex: 0,
      tabs: []
    }
  },
  created () {
    this.tabs = this.$children
  },
  mounted() {
    this.selectTab(0)
  },
  methods: {
    selectTab (i) {
      this.selectedIndex = i
      this.tabs.forEach((tab, index) => { tab.isActive = (index === i) })
      this.$emit('tabChanged', this.tabs[this.selectedIndex].$attrs['data-id'])
    }
  }
}
