
import 'swiper/css/swiper.min.css'
import Swiper from 'swiper'
export default {
  name: 'ThumbnailImageGallery',
  props: ['id', 'images'],
  data() {
    return {
      swiper: null,
      swiperThumb: null
    }
  },
  mounted() {
    this.swiperThumb = new Swiper(`#${this.id}-thumbs`, {
      slidesPerView: 5,
      spaceBetween: 0,
      direction: 'horizontal',
      loop: false,
    })
    this.swiper = new Swiper(`#${this.id}-gallery`, {
      slidesPerView: 1,
      direction: 'horizontal',
      effect: 'fade',
      loop: false,
      // fadeEffect: {
      //   crossFade: true,
      // },
      // centeredSlides: true,
      spaceBetween: 15,
      thumbs: {
        swiper: this.swiperThumb,
        autoScrollOffset: 1
      }
    })
  },
  methods: {
    jumpToSlide(id){
      const index = this.images.findIndex(i => i.gallery_image_id === id)
      this.swiper?.slideTo(index)
      this.swiperThumb?.slideTo(index)
    },
    extractVideoId(videoUrl) {
      return videoUrl.embed_url.substr(
        videoUrl.embed_url.indexOf('v=') + 2,
        videoUrl.embed_url.length
      )
    }
  }
}
