import { render, staticRenderFns } from "./AddToCart.vue?vue&type=template&id=40841064&scoped=true&lang=pug"
import script from "./AddToCart.vue?vue&type=script&lang=js"
export * from "./AddToCart.vue?vue&type=script&lang=js"
import style0 from "./AddToCart.vue?vue&type=style&index=0&id=40841064&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40841064",
  null
  
)

export default component.exports