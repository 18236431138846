
export default {
  name: 'PaypalWidget',
  props: ['placement', 'amount', 'theme', 'sku', 'currency'],
  computed:{
    country(){
      const country = this.$store.getters.country
      return country.code
    },
    lang() {
      return this.$i18n.locale
    },
    locale() {
      return `${this.lang}-${this.country}`
    },
    isHidden() {
      return this.sku && this.sku.includes('MK-FBRAA')
    },
    
  },
  watch: {
    locale(){
      this.refresh()
    },
    amount(){
      this.refresh()
    },
  },
  mounted(){
    setTimeout(()=>{
      this.refresh()
    }, 1000)
  },
  methods: {
    refresh(){
      // this.$refs.paypalWidget.Messages.render()
    }
  }
}
